import React from "react";
import Header from "../../components/Header";
import contactBanner from "../../assets/img/alanya.jpg"; // Geniş görsel için placeholder
import Footer from "../../components/Footer";

const Contact = () => {
  return (
    <div>
      {/* Header */}
      <Header />

      {/* Banner Image */}
      <div className="w-full h-[400px] relative">
        {/* Header'ın altında boşluk bırakmak için margin-top ayarı */}
        <img
          src={contactBanner}
          alt="Contact Us"
          className="w-full h-full object-cover"
        />
        <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <h1 className="text-3xl md:text-4xl font-bold text-white">
            Contact Us
          </h1>
        </div>
      </div>

      {/* Contact Information Section */}
      <div className="container mx-auto pb-16 pt-4 px-4">
        <div className="text-center mb-12">
          <h2 className="text-2xl md:text-3xl font-bold">Get In Touch</h2>
          <p className="text-lg text-gray-500">Our Contact Information</p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {/* Address */}
          <div className="bg-white shadow-lg p-8 rounded-md text-center">
            <div className="text-4xl md:text-5xl text-teal-600 mb-4">
              <i className="fas fa-map-marker-alt"></i>
            </div>
            <h3 className="text-lg md:text-xl font-bold mb-2">Our Address</h3>
            <p className="text-gray-600">
              2690 Hiltona Street, Victoria Road, New York, Canada
            </p>
          </div>

          {/* Phone Number */}
          <div className="bg-white shadow-lg p-8 rounded-md text-center">
            <div className="text-4xl md:text-5xl text-teal-600 mb-4">
              <i className="fas fa-phone-alt"></i>
            </div>
            <h3 className="text-lg md:text-xl font-bold mb-2">Phone Number</h3>
            <p className="text-gray-600">+01 234 567 890</p>
            <p className="text-gray-600">+09 876 543 210</p>
          </div>

          {/* Email Address */}
          <div className="bg-white shadow-lg p-8 rounded-md text-center">
            <div className="text-4xl md:text-5xl text-teal-600 mb-4">
              <i className="fas fa-envelope"></i>
            </div>
            <h3 className="text-lg md:text-xl font-bold mb-2">Email Address</h3>
            <p className="text-gray-600">mailinfo00@tourm.com</p>
            <p className="text-gray-600">support24@tourm.com</p>
          </div>
        </div>

        <div className="container mx-auto py-16 px-4">
          <div className="w-full h-[450px]">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3205.3275859275436!2d32.00524925454704!3d36.54621354946594!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14dc9863a92cc475%3A0xb69cf8f10a1128a7!2sTravok!5e0!3m2!1str!2str!4v1729072178566!5m2!1str!2str"
              width="100%"
              height="450"
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
      </div>

      {/* Footer */}
      <Footer />
    </div>
  );
};

export default Contact;
