import React, { useState } from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import alanyaImage from "../../assets/img/alanya.jpg";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";

const services = [
  {
    title: "Kayaking",
    listings: 25,
    description:
      "Discover beautiful waters with our ski service at selected ski resorts around the world.",
  },
  {
    title: "Safe Flight",
    listings: 26,
    description:
      "Experience exciting and safe flights over scenic areas. We will do our best to ensure you reach your destination safely and smoothly. As you glide through the air, take in the breathtaking views of mountains, seas, and lush landscapes that stretch as far as the eye can see.",
  },
  {
    title: "Entertainment",
    listings: 27,
    description: "Enjoy top-notch entertainment and shows during your tour.",
  },
  {
    title: "Delicious Food",
    listings: 28,
    description:
      "Taste the best local and international cuisines from around the world.",
  },
  {
    title: "Special Treatment",
    listings: 29,
    description: "Quality service with our specially trained staff.",
  },
];

const Service = () => {
  const [selectedService, setSelectedService] = useState(null);

  const openModal = (service) => {
    setSelectedService(service);
  };

  const closeModal = () => {
    setSelectedService(null);
  };

  return (
    <div>
      {/* Header */}
      <Header />

      {/* Banner Image */}
      <div className="w-full h-[400px] relative">
        <img
          src={alanyaImage}
          alt="Contact Us"
          className="w-full h-full object-cover"
        />
        <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <h1 className="text-3xl md:text-4xl font-bold text-white">
            Our Services
          </h1>
        </div>
      </div>
      {/* Introduction Paragraph */}
      <div className="container mx-auto py-2 mt-4 px-4 text-left">
        <p className="text-lg text-gray-700">
          At Travok Travel, we are dedicated to providing exceptional services
          that enhance your travel experience. Whether you're seeking thrilling
          adventures, luxurious relaxation, or cultural exploration, our diverse
          range of services ensures that every moment of your journey is
          unforgettable. Explore the unique activities and amenities we offer to
          make your travels even more enjoyable and fulfilling.
        </p>
      </div>

      {/* Services Section */}
      <div className="container mx-auto py-8 px-4">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-8">
          {services.map((service, index) => (
            <div
              key={index}
              className="bg-white shadow-lg rounded-lg overflow-hidden"
            >
              {/* Image */}
              <div className="w-full h-[150px]">
                <img
                  src={alanyaImage}
                  alt={service.title}
                  className="w-full h-full object-cover"
                />
              </div>
              {/* Content */}
              <div className="bg-[#fff] p-2 text-center">
                <h3 className="text-lg font-bold">{service.title}</h3>
                <button
                  onClick={() => openModal(service)}
                  className="border border-[#ED7D31] text-[#ED7D31] rounded-lg px-4 py-2 hover:bg-[#ED7D31] hover:text-white transition-colors"
                >
                  View Details
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Modal */}
      {selectedService && (
        <div
          id="default-modal"
          tabIndex="-1"
          aria-hidden="true"
          className="fixed top-0 left-0 right-0 z-50 flex justify-center items-center w-full h-full bg-black bg-opacity-50"
        >
          <div className="relative p-4 w-full max-w-2xl">
            <div className="relative bg-[#4F4A45] rounded-lg shadow">
              {/* Modal header */}
              <div className="flex items-center justify-between p-4 border-b rounded-t dark:border-gray-600">
                <h3 className="text-xl font-semibold text-[#ED7D31]">
                  {selectedService.title}
                </h3>
                <button
                  type="button"
                  className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                  onClick={closeModal}
                >
                  <svg
                    className="w-3 h-3"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 14 14"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                    />
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
              </div>
              {/* Modal body */}
              <div className="p-6 space-y-6">
                <Swiper className="mySwiper">
                  <SwiperSlide>
                    <img
                      src={alanyaImage}
                      alt="Service Image 1"
                      className="w-full h-[200px] object-cover rounded-lg"
                    />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img
                      src={alanyaImage}
                      alt="Service Image 2"
                      className="w-full h-[200px] object-cover rounded-lg"
                    />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img
                      src={alanyaImage}
                      alt="Service Image 3"
                      className="w-full h-[200px] object-cover rounded-lg"
                    />
                  </SwiperSlide>
                </Swiper>
                <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                  {selectedService.description}
                </p>
              </div>
              {/* Modal footer */}
              <div className="flex items-center p-6 rounded-b dark:border-gray-600"></div>
            </div>
          </div>
        </div>
      )}

      {/* Footer */}
      <Footer />
    </div>
  );
};

export default Service;
