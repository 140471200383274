import React, { useState, useEffect } from "react";
import Header from "../../components/Header";
import { FiFilter } from "react-icons/fi";
import alanya from "../../assets/img/alanya.jpg";
import { useNavigate } from "react-router-dom";
import SpeedDial from "../../components/SpeedDial";
import SearchForm from "../../components/SearchForm";
import ContactModal from "../../components/ContactModal";
import Footer from "../../components/Footer";
import axios from "axios";

// const tours = [
//   {
//     id: 1,
//     name: "Greece Tour Package",
//     price: "$980.00",
//     duration: "7",
//     rating: "4.8",
//     budgetFriendly: false,
//     images: [alanya, alanya, alanya],
//     imageAlt: "Greece Tour Package",
//   },
//   {
//     id: 2,
//     name: "Dubai Tour Package",
//     price: "$980.00",
//     duration: "7",
//     rating: "4.8",
//     budgetFriendly: false,
//     images: [alanya, alanya, alanya],
//     imageAlt: "Dubai Tour Package",
//   },
//   {
//     id: 3,
//     name: "Spain Tour Package",
//     price: "$950.00",
//     duration: "6",
//     rating: "4.7",
//     budgetFriendly: false,
//     images: [alanya, alanya, alanya],
//     imageAlt: "Spain Tour Package",
//   },
//   {
//     id: 4,
//     name: "Italy Tour Package",
//     price: "$980.00",
//     duration: "7",
//     rating: "4.8",
//     budgetFriendly: false,
//     images: [alanya, alanya, alanya],
//     imageAlt: "Italy Tour Package",
//   },
//   {
//     id: 5,
//     name: "Egypt Tour Package",
//     price: "$880.00",
//     duration: "5",
//     rating: "4.5",
//     budgetFriendly: true,
//     images: [alanya, alanya, alanya],
//     imageAlt: "Egypt Tour Package",
//   },
//   {
//     id: 6,
//     name: "Thailand Tour Package",
//     price: "$780.00",
//     duration: "4",
//     rating: "4.4",
//     budgetFriendly: true,
//     images: [alanya, alanya, alanya],
//     imageAlt: "Thailand Tour Package",
//   },
//   {
//     id: 7,
//     name: "Turkey Tour Package",
//     price: "$650.00",
//     duration: "3",
//     rating: "4.6",
//     budgetFriendly: true,
//     images: [alanya, alanya, alanya],
//     imageAlt: "Turkey Tour Package",
//   },
//   {
//     id: 8,
//     name: "France Tour Package",
//     price: "$1,200.00",
//     duration: "8",
//     rating: "4.9",
//     budgetFriendly: false,
//     imageAlt: "France Tour Package",
//     images: [alanya, alanya, alanya],
//   },
// ];

const TourList = () => {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [budgetFriendly, setBudgetFriendly] = useState(false);
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const [tours, setTours] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const toggleDrawer = () => {
    setDrawerOpen(!isDrawerOpen);
  };

  useEffect(() => {
    const fetchTours = async () => {
      try {
        const response = await axios.get(
          "https://travoktravel.com/api/v1/tour/getAllTours"
        );
        if (response.data.success) {
          setTours(response.data.data); // Gelen veriyi turlar olarak kaydediyoruz
        } else {
          alert("Turları getirme başarısız oldu.");
        }
      } catch (error) {
        console.error("Turları çekerken bir hata oluştu:", error);
      } finally {
        setIsLoading(false); // Yükleme durumu sona erdi
      }
    };

    fetchTours();
  }, []);

  const startIndex = (currentPage - 1) * 8;
  const filteredTours = tours.filter(
    (tour) => !budgetFriendly || tour.budgetFriendly
  );
  const selectedTours = filteredTours.slice(startIndex, startIndex + 8);
  const [searchInput, setSearchInput] = useState("");

  const [isDropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  return (
    <div className="relative">
      <Header />
      <SpeedDial />
      {/* Main Content */}
      <div className="container mx-auto mt-20">
        {/* Main Banner */}
        <div className="bg-white rounded-lg shadow-lg overflow-hidden mb-6">
          <div className="relative w-full h-64 md:h-80">
            <img
              src={alanya}
              alt="Promotional Banner"
              className="w-full h-full object-cover"
            />
            <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center">
              <div className="text-center text-white px-4">
                <h2 className="text-2xl md:text-3xl font-bold mb-2">
                  26-29 Ekim
                </h2>
                <p className="text-lg md:text-xl">
                  Uzun Hafta Sonu Tatilinin Tadını Çıkar!
                </p>
                <div className="mt-4 bg-orange-600 inline-block px-3 py-1 rounded-full">
                  <span className="text-sm md:text-base">
                    %50'ye Varan İndirimler
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-white rounded-lg shadow-md p-6 mb-2">
          {/* Form Fields */}
          <div className="flex flex-wrap gap-4">
            <select
              className="flex-grow bg-gray-100 rounded-lg p-2.5 border border-gray-300 w-full sm:w-auto"
              defaultValue=""
            >
              <option value="" disabled>
                Destination
              </option>
              <option value="Greece">Greece</option>
              <option value="Dubai">Dubai</option>
              <option value="Spain">Spain</option>
              <option value="Italy">Italy</option>
              <option value="Egypt">Egypt</option>
              <option value="Thailand">Thailand</option>
            </select>
            <select
              className="bg-gray-100 rounded-lg p-2.5 border border-gray-300 w-full sm:w-auto"
              defaultValue=""
            >
              <option value="" disabled>
                Type
              </option>
              <option value="Adventure">Adventure</option>
              <option value="Relaxation">Relaxation</option>
              <option value="Cultural">Cultural</option>
            </select>
            <select
              className="bg-gray-100 rounded-lg p-2.5 border border-gray-300 w-full sm:w-auto"
              defaultValue=""
            >
              <option value="" disabled>
                Activity
              </option>
              <option value="Kayaking">Kayaking</option>
              <option value="Hiking">Hiking</option>
              <option value="Sightseeing">Sightseeing</option>
            </select>
            <input
              type="date"
              placeholder="Start Date"
              className="bg-gray-100 rounded-lg p-2.5 border border-gray-300 w-full sm:w-auto"
            />
            <input
              type="date"
              placeholder="End Date"
              className="bg-gray-100 rounded-lg p-2.5 border border-gray-300 w-full sm:w-auto"
            />
            <button className="bg-[#ED7D31] text-white px-6 py-2 rounded-lg w-full sm:w-auto">
              <span className="sr-only">Search</span> 🔍
            </button>
          </div>
          {/* Popular Searches */}
          <div className="mt-4 text-sm text-gray-500">
            <span>Popüler Aramalar: </span>
            <a href="#" className="text-[#ED7D31] hover:underline">
              Erken Rezervasyon Fırsatları →
            </a>
            <a href="#" className="ml-4 text-[#ED7D31] hover:underline">
              Son Dakika Tur Fırsatları →
            </a>
            <a href="#" className="ml-4 text-[#ED7D31] hover:underline">
              Alanya Turları →
            </a>
            <a href="#" className="ml-4 text-[#ED7D31] hover:underline">
              Safari Turları →
            </a>
          </div>
        </div>
      </div>

      {/* Dropdown for Mobile and Buttons for Desktop */}
      <div className="container flex justify-center mx-auto mb-6">
        {/* Mobil Görünümde Sırala ve Filtrele Butonları */}
        <div className="block md:hidden w-full px-4">
          <div className="flex gap-4">
            {/* Sırala Button with Dropdown */}
            <div className="relative w-1/2">
              <button
                className="w-full bg-[#ED7D31] text-white py-3 rounded-md hover:bg-[#e66a23]"
                onClick={toggleDropdown}
              >
                Sırala
              </button>
              {isDropdownOpen && (
                <div className="absolute w-full bg-white border rounded-md shadow-lg mt-0 z-10">
                  <button
                    className="w-full bg-[#ED7D31] text-white text-left px-4 py-2 hover:bg-[#ED7D31]"
                    onClick={() => {
                      alert("En Popüler Seçildi");
                      setDropdownOpen(false); // Close dropdown after selection
                    }}
                  >
                    En Popüler
                  </button>
                  <button
                    className="w-full bg-[#ED7D31] text-white text-left px-4 py-2 hover:bg-[#ED7D31]"
                    onClick={() => {
                      alert("Fiyata Göre Artan Seçildi");
                      setDropdownOpen(false);
                    }}
                  >
                    Fiyata Göre Artan
                  </button>
                  <button
                    className="w-full bg-[#ED7D31] text-white text-left px-4 py-2 hover:bg-[#ED7D31]"
                    onClick={() => {
                      alert("Fiyata Göre Azalan Seçildi");
                      setDropdownOpen(false);
                    }}
                  >
                    Fiyata Göre Azalan
                  </button>
                </div>
              )}
            </div>

            <button
              onClick={toggleDrawer}
              className="w-1/2 bg-[#ED7D31] text-white py-3 rounded-md hover:bg-[#d96928] transition-all"
            >
              <FiFilter className="inline mr-2" /> Filtrele
            </button>
          </div>
        </div>

        {/* Masaüstü Butonları */}
        <div className="hidden md:flex w-full">
          <button
            className="flex-grow bg-[#ED7D31] text-white px-12 py-3 rounded-r-md rounded-l-md hover:bg-[#e66a23]"
            onClick={() => alert("En Popüler Seçildi")}
          >
            En Popüler
          </button>
          <button
            className="flex-grow bg-[#ED7D31] text-white px-12 py-3 border-l border-r border-white rounded-r-md rounded-l-md hover:bg-[#e66a23]"
            onClick={() => alert("Fiyata Göre Artan Seçildi")}
          >
            Fiyata Göre Artan
          </button>
          <button
            className="flex-grow bg-[#ED7D31] text-white px-12 py-3 rounded-r-md rounded-l-md hover:bg-[#e66a23]"
            onClick={() => alert("Fiyata Göre Azalan Seçildi")}
          >
            Fiyata Göre Azalan
          </button>
        </div>
      </div>

      <div className="container mx-auto py-8 mt-[10px] px-4 flex flex-col lg:flex-row">
        {/* Filter Sidebar for Larger Screens */}
        <div className="hidden lg:block w-full lg:w-1/3 lg:mr-4">
          {/* Filter Sidebar (Always visible on large screens) */}
          <div className="bg-white p-4 rounded-lg shadow-lg">
            <h5 className="inline-flex items-center mb-4 text-base font-semibold text-[#ED7D31]">
              Filtreler
            </h5>
            <div className="space-y-4">
              <h2 className="text-xl font-bold">Filters</h2>

              <div className="space-y-2">
                <h3 className="text-lg font-semibold">Tour Duration</h3>
                <ul className="space-y-2">
                  <li className="flex items-center">
                    <input type="checkbox" className="mr-2" id="duration1" />
                    <label htmlFor="duration1">4-6 Days</label>
                  </li>
                  <li className="flex items-center">
                    <input type="checkbox" className="mr-2" id="duration2" />
                    <label htmlFor="duration2">7-9 Days</label>
                  </li>
                  <li className="flex items-center">
                    <input type="checkbox" className="mr-2" id="duration3" />
                    <label htmlFor="duration3">10-12 Days</label>
                  </li>
                </ul>
              </div>

              <div className="space-y-2">
                <h3 className="text-lg font-semibold">Transportation Type</h3>
                <ul className="space-y-2">
                  <li className="flex items-center">
                    <input type="checkbox" className="mr-2" id="transport1" />
                    <label htmlFor="transport1">Flight</label>
                  </li>
                  <li className="flex items-center">
                    <input type="checkbox" className="mr-2" id="transport2" />
                    <label htmlFor="transport2">Bus</label>
                  </li>
                  <li className="flex items-center">
                    <input type="checkbox" className="mr-2" id="transport3" />
                    <label htmlFor="transport3">Boat</label>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        {/* Tour Cards Section */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
          {isLoading ? (
            <p>Loading</p>
          ) : (
            selectedTours.map((tour) => (
              <div
                key={tour._id}
                onClick={() => navigate(`/tour-detail/${tour._id}`)} // Kartın tamamını tıklanabilir hale getiriyoruz
                className="bg-white shadow-lg rounded-lg p-4 hover:shadow-xl hover:scale-105 transition-transform duration-300 cursor-pointer"
              >
                {/* Tur Resmi */}
                <div className="h-40 w-full rounded-t-lg overflow-hidden">
                  <img
                    src={tour.imageCover || alanya} // Varsayılan resim kullanımı
                    alt={tour.title}
                    className="object-cover w-full h-full"
                  />
                </div>

                {/* Tur Detayları */}
                <div className="p-4">
                  <h3 className="text-md font-medium text-[#4F4A45] truncate">
                    {tour.title}
                  </h3>
                  <p className="text-sm text-gray-600 line-clamp-2 mt-2">
                    {tour.description}
                  </p>

                  {/* Tur Fiyatı ve Derecelendirme */}
                  <div className="flex flex-col mt-4">
                    <div className="flex items-center">
                      <span className="text-yellow-500 mr-1">&#9733;</span>
                      <span className="text-yellow-500 mr-1">&#9733;</span>
                      <span className="text-yellow-500 mr-1">&#9733;</span>
                      <span className="text-yellow-500 mr-1">&#9733;</span>
                      <span className="text-yellow-500 mr-1">&#9733;</span>
                      <p className="text-sm">
                        {tour.ratingsAverage || "Derecelendirme yok"}
                      </p>
                    </div>
                    <p className="text-lg font-bold text-green-600">
                      ${tour.priceTable[0]?.price || "N/A"}/Person
                    </p>
                  </div>
                </div>
              </div>
            ))
          )}
        </div>
      </div>

      {/* Drawer for Mobile */}
      <div
        className={`fixed top-0 left-0 z-50 h-screen p-4 overflow-y-auto transition-transform bg-white w-64 lg:hidden ${
          isDrawerOpen ? "translate-x-0" : "-translate-x-full"
        }`}
      >
        <h5 className="inline-flex items-center mb-4 text-base font-semibold text-[#ED7D31]">
          Filtreler
        </h5>
        <button
          onClick={toggleDrawer}
          className="text-[#ED7D31] bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 absolute top-2.5 right-2.5 flex items-center justify-center"
        >
          <svg
            className="w-3 h-3"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 14 14"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
            />
          </svg>
        </button>

        {/* Drawer Filter Section */}
        <div className="space-y-4">
          <h2 className="text-xl font-bold">Filters</h2>
        </div>
      </div>

      <ContactModal />
      <Footer />
    </div>
  );
};

export default TourList;
