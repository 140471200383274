import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import Header from "../../components/Header";
import alanya from "../../assets/img/alanya.jpg";
import Footer from "../../components/Footer";

const BlogDetail = () => {
  const { blogId } = useParams();
  const [blogData, setBlogData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchBlogData = async () => {
      try {
        const response = await axios.get(
          `https://travoktravel.com/api/v1/blog/getBlogById/${blogId}`
        );
        if (response.data.success) {
          setBlogData(response.data.data);
        } else {
          alert("Blog yüklenemedi.");
        }
      } catch (error) {
        console.error("Blog verisi alınırken hata oluştu:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchBlogData();
  }, [blogId]);

  // Mock data for related posts (since we don't have related blog data from API)
  const relatedPosts = [
    {
      id: 1,
      title: "The best time to visit Japan & enjoy the cherry blossoms",
      date: "22/6/2024",
      image: "https://placehold.co/80x80",
    },
    {
      id: 2,
      title: "The 7 amazing destinations for adventure seekers",
      date: "25/6/2024",
      image: "https://placehold.co/80x80",
    },
    {
      id: 3,
      title: "10 Reasons why you should visit New Jersey",
      date: "27/6/2024",
      image: "https://placehold.co/80x80",
    },
  ];

  if (isLoading) {
    return <p>Yükleniyor...</p>;
  }

  if (!blogData) {
    return <p>Blog bulunamadı.</p>;
  }

  return (
    <div>
      {/* Include the Header */}
      <Header />

      <div className="w-full h-[400px] relative">
        <img
          src={alanya}
          alt="Blog Cover"
          className="w-full h-full object-cover"
        />
      </div>

      {/* Blog Content Section */}
      <section className="container mx-auto py-16 px-4">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {/* Main Blog Content */}
          <div className="col-span-1 md:col-span-2">
            <img
              src="https://placehold.co/872x490"
              alt="Blog Cover"
              className="rounded-lg mb-8"
            />
            <h2 className="text-3xl font-semibold text-[#4F4A45] mb-6">
              {blogData.title}
            </h2>
            {blogData.content.map((section, index) => (
              <div key={index} className="mb-8">
                <h3 className="text-2xl font-semibold text-[#4F4A45] mb-4">
                  {section.subtitle}
                </h3>
                <p className="text-lg text-gray-700 mb-6">
                  {section.paragraph}
                </p>
              </div>
            ))}
          </div>

          {/* Sidebar */}
          <aside className="w-full md:w-auto">
            <div className="bg-white shadow-md rounded-lg p-8">
              <h3 className="text-lg font-semibold text-[#4F4A45] mb-4">
                Related Posts
              </h3>
              <div className="space-y-4">
                {relatedPosts.map((post) => (
                  <div key={post.id} className="flex items-center space-x-6">
                    <a
                      href={post.link}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="flex-shrink-0"
                    >
                      <img
                        src={post.image}
                        alt={post.title}
                        className="w-20 h-20 rounded-md object-cover transition-transform duration-300 ease-in-out hover:scale-110"
                      />
                    </a>
                    <div>
                      <h3 className="text-sm font-semibold">
                        <a
                          href={post.link}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {post.title}
                        </a>
                      </h3>
                      <div className="flex items-center text-gray-500 text-sm mt-1">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          className="w-4 h-4 mr-1"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M8.25 6.75V5.25a2.25 2.25 0 114.5 0v1.5m1.5 0V5.25a3.75 3.75 0 00-7.5 0v1.5m7.5 0h1.5A2.25 2.25 0 0120.25 9v10.5A2.25 2.25 0 0118 21.75H6a2.25 2.25 0 01-2.25-2.25V9a2.25 2.25 0 012.25-2.25h1.5m7.5 0v1.5a3.75 3.75 0 11-7.5 0V6.75m7.5 0h-7.5"
                          />
                        </svg>
                        <p>{post.date}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </aside>
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default BlogDetail;
