import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, EffectFade } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/effect-fade";
import slider1 from "../assets/img/slider1.jpg";
import slider2 from "../assets/img/slider2.jpg";

const HeroSection = () => {
  return (
    <div className="relative">
      <Swiper
        modules={[Navigation, Pagination, EffectFade]}
        spaceBetween={0}
        slidesPerView={1}
        navigation={{
          nextEl: ".slider-next",
          prevEl: ".slider-prev",
        }}
        effect="fade"
        className="w-full h-[300px] md:h-[500px] shadow-lg"
      >
        <SwiperSlide>
          <div
            className="relative h-full bg-red-500"
            style={{
              backgroundImage: `url(${slider1})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div className="absolute inset-0 bg-gray-600 opacity-20"></div>
            {/* <div className="container mx-auto h-full flex flex-col justify-center items-center">
              <span className="text-white text-lg mb-4 animate-slideinup">
                Get unforgettable pleasure with us
              </span>
              <h1 className="text-white text-4xl md:text-6xl font-bold mb-8 animate-slideinup">
                Natural Wonder of the World
              </h1>
            </div> */}
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div
            className="relative h-full bg-gray-500"
            style={{
              backgroundImage: `url(${slider2})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div className="absolute inset-0 bg-gray-600 opacity-50"></div>
            {/* <div className="container mx-auto h-full flex flex-col justify-center items-center">
              <span className="text-white text-lg mb-4 animate-slideinup">
                Get unforgettable pleasure with us
              </span>
              <h1 className="text-white text-4xl md:text-6xl font-bold mb-8 animate-slideinup">
                Let’s make your best trip with us
              </h1>
            </div> */}
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

export default HeroSection;
