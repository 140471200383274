import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";

const testimonials = [
  {
    name: "Andrew Simon",
    role: "Traveller",
    review:
      "A sophisticated rainwater harvesting system collects and filters rainwater for irrigation and non-potable uses, reducing reliance on municipal water sources.",
    rating: 5,
  },
  {
    name: "Alex Jordan",
    role: "Traveller",
    review:
      "Throughout the interior, eco-friendly materials like reclaimed wood, bamboo flooring, and recycled glass countertops create a luxurious yet sustainable ambiance.",
    rating: 4,
  },
  {
    name: "Maria Doe",
    role: "Traveller",
    review:
      "A home that perfectly blends sustainability and luxury until I discovered Ecoland Residence. The commitment to eco-friendly living made it feel like home.",
    rating: 5,
  },
  {
    name: "Maria Doe",
    role: "Traveller",
    review:
      "A home that perfectly blends sustainability and luxury until I discovered Ecoland Residence. The commitment to eco-friendly living made it feel like home.",
    rating: 5,
  },
  {
    name: "Maria Doe",
    role: "Traveller",
    review:
      "A home that perfectly blends sustainability and luxury until I discovered Ecoland Residence. The commitment to eco-friendly living made it feel like home.",
    rating: 5,
  },
  // More testimonials can be added here...
];

const TestimonialArea = () => {
  return (
    <section className="py-1">
      <div className="container mx-auto">
        <div className="text-center mb-2">
          <h2 className="text-3xl font-bold">What Client Say About us</h2>
        </div>
        <Swiper
          slidesPerView={3}
          spaceBetween={50}
          grabCursor={true}
          pagination={{ clickable: true }}
          modules={[Pagination]}
          breakpoints={{
            320: {
              slidesPerView: 1,
              spaceBetween: 10,
            },
            640: {
              slidesPerView: 2,
              spaceBetween: 15,
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            1024: {
              slidesPerView: 3,
              spaceBetween: 20,
            },
            1280: {
              slidesPerView: 4,
              spaceBetween: 30,
            },
          }}
          className="mySwiper p-8"
        >
          {testimonials.map((testimonial, index) => (
            <SwiperSlide key={index}>
              <div className="bg-white rounded-lg shadow-lg p-6 max-w-md mx-auto flex flex-col items-center">
                <div className="w-16 h-16 rounded-full bg-gray-300 mb-4 flex items-center justify-center">
                  {/* Placeholder for user image */}
                  <span className="text-lg font-semibold">72 x 72</span>
                </div>
                <h3 className="text-lg font-semibold">{testimonial.name}</h3>
                <p className="text-sm text-gray-500">{testimonial.role}</p>
                <div className="flex items-center mt-2 mb-4">
                  {Array(testimonial.rating)
                    .fill()
                    .map((_, i) => (
                      <span key={i} className="text-yellow-500">
                        &#9733;
                      </span>
                    ))}
                </div>
                <p className="text-gray-700 text-left line-clamp-4">
                  {testimonial.review}
                </p>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </section>
  );
};

export default TestimonialArea;
