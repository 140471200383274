import React, { useState } from "react";
import Header from "../../components/Header";
import Stepper from "../../components/Stepper";
import tourImage from "../../assets/img/alanya.jpg"; // Tur resmini buraya ekle

const Reservation = () => {
  const [reservationInfo, setReservationInfo] = useState({
    phone: "",
    email: "",
    name: "",
    surname: "",
    gender: "Kadın",
  });

  const handleChange = (e) => {
    setReservationInfo({
      ...reservationInfo,
      [e.target.name]: e.target.value,
    });
  };

  const [guestCount, setGuestCount] = useState(2); // Varsayılan misafir sayısı
  const [guests, setGuests] = useState(
    Array(guestCount).fill({
      name: "",
      surname: "",
      id: "",
      dob: "",
      gender: "",
    })
  );

  // Misafir sayısını değiştirirken çağrılacak fonksiyon
  const handleGuestCountChange = (e) => {
    const newCount = parseInt(e.target.value);
    setGuestCount(newCount);

    // Misafir sayısı arttığında/azaldığında guests array'ini güncelle
    const updatedGuests = Array(newCount).fill({
      name: "",
      surname: "",
      id: "",
      dob: "",
      gender: "",
    });
    setGuests(updatedGuests);
  };

  // Misafir bilgilerini değiştirirken çağrılacak fonksiyon
  const handleGuestChange = (e, index) => {
    const { name, value } = e.target;
    const updatedGuests = [...guests];
    updatedGuests[index] = {
      ...updatedGuests[index],
      [name.split("-")[0]]: value,
    };
    setGuests(updatedGuests);
  };

  const handleNextStep = () => {
    // Ödeme sayfasına yönlendirme
    window.location.href = "/payment";
  };

  return (
    <>
      <Header />
      <div className="container mx-auto mt-10 py-8 px-4 md:px-6 mt-6">
        <div className="flex flex-col mt-5 md:flex-row">
          {/* Sol Bölüm: Rezervasyon Bilgileri */}
          <div className="w-full md:w-2/3">
            <h2 className="text-2xl font-semibold mb-6">
              İletişim ve Fatura Bilgileri
            </h2>

            {/* Stepper */}
            <Stepper currentStep={2} />
            {/* Stepper */}

            <div class="bg-white p-6 shadow-md rounded-md mb-4">
              <p class="text-gray-700">
                Oturum açarak daha hızlı ilerleyebilirsiniz!
              </p>
              <div class="flex space-x-4">
                <button class="px-4 py-2 bg-[#173A67] text-white rounded-md hover:bg-[#0d2b54]">
                  Giriş Yap
                </button>
                <button class="px-4 py-2 border border-[#173A67] text-[#173A67] rounded-md hover:bg-[#f1f5f9]">
                  Üye Ol
                </button>
              </div>
            </div>

            <div className="bg-white p-6 shadow-md rounded-md mb-4">
              {/* İletişim Bilgileri */}
              <h2 className="text-lg font-bold mb-4 text-gray-700">
                İletişim ve Fatura Bilgileri
              </h2>

              <div className="flex flex-col md:flex-row gap-4">
                <div className="w-full">
                  <label className="block text-gray-700 text-sm font-bold mb-2">
                    Ad
                  </label>
                  <input
                    type="text"
                    name="firstName"
                    value={reservationInfo.firstName}
                    onChange={handleChange}
                    className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none"
                    placeholder="Adınız"
                  />
                </div>
                <div className="w-full">
                  <label className="block text-gray-700 text-sm font-bold mb-2">
                    Soyad
                  </label>
                  <input
                    type="text"
                    name="lastName"
                    value={reservationInfo.lastName}
                    onChange={handleChange}
                    className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none"
                    placeholder="Soyadınız"
                  />
                </div>
              </div>

              <div className="flex flex-col md:flex-row gap-4 mt-4">
                <div className="w-full">
                  <label className="block text-gray-700 text-sm font-bold mb-2">
                    Cep Telefonu
                  </label>
                  <div className="flex items-center space-x-2">
                    <span className="px-3 py-2 bg-gray-100 border border-gray-300 rounded-l-md">
                      +90
                    </span>
                    <input
                      type="text"
                      name="phone"
                      value={reservationInfo.phone}
                      onChange={handleChange}
                      className="w-full px-3 py-2 border border-gray-300 rounded-r-md focus:outline-none"
                      placeholder="XXXX XXX XX XX"
                    />
                  </div>
                </div>
                <div className="w-full">
                  <label className="block text-gray-700 text-sm font-bold mb-2">
                    E-Posta Adresi
                  </label>
                  <input
                    type="email"
                    name="email"
                    value={reservationInfo.email}
                    onChange={handleChange}
                    className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none"
                    placeholder="email@example.com"
                  />
                </div>
              </div>

              {/* Adres Bilgileri */}
              <div className="mt-6">
                <label className="block text-gray-700 text-sm font-bold mb-2">
                  Adres
                </label>
                <input
                  type="text"
                  name="address"
                  value={reservationInfo.address}
                  onChange={handleChange}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none"
                  placeholder="Adresiniz"
                />
              </div>

              <div className="flex flex-col md:flex-row gap-4 mt-4">
                <div className="w-full">
                  <label className="block text-gray-700 text-sm font-bold mb-2">
                    Ülke
                  </label>
                  <input
                    type="text"
                    name="country"
                    value={reservationInfo.country}
                    onChange={handleChange}
                    className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none"
                    placeholder="Ülkeniz"
                  />
                </div>
                <div className="w-full">
                  <label className="block text-gray-700 text-sm font-bold mb-2">
                    Şehir
                  </label>
                  <input
                    type="text"
                    name="city"
                    value={reservationInfo.city}
                    onChange={handleChange}
                    className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none"
                    placeholder="Şehir"
                  />
                </div>
              </div>

              {/* Fatura Bilgileri */}
              <div className="mt-4">
                <input type="checkbox" className="mr-2" id="billingInfo" />
                <label htmlFor="billingInfo" className="text-sm text-gray-700">
                  Fatura bilgilerimi değiştirmek istiyorum.
                </label>
              </div>
            </div>

            <div className="bg-blue-100 p-4 rounded-md mb-4 text-sm text-blue-700">
              Seçtiğiniz tura {guestCount} yetişkin için rezervasyon
              yapıyorsunuz.
              <input
                type="number"
                min="1"
                max="10"
                value={guestCount}
                onChange={handleGuestCountChange}
                className="ml-4 w-16 px-4 py-2 border border-blue-600 rounded-md"
              />
            </div>

            {/* <h2 className="text-2xl font-semibold mb-6">Misafir Bilgileri</h2> */}

            {/* {guests.map((guest, index) => (
              <div
                key={index}
                className="bg-white p-6 shadow-md rounded-md mb-4"
              >
                <h3 className="text-lg font-semibold mb-4">
                  Misafir {index + 1}
                </h3>
                <div className="flex flex-col md:flex-row gap-4">
                  <div className="w-full">
                    <label className="block text-gray-700 text-sm font-bold mb-2">
                      Ad
                    </label>
                    <input
                      type="text"
                      name={`name-${index}`}
                      value={guest.name}
                      onChange={(e) => handleGuestChange(e, index)}
                      className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none"
                      placeholder="Adını giriniz"
                    />
                  </div>
                  <div className="w-full">
                    <label className="block text-gray-700 text-sm font-bold mb-2">
                      Soyad
                    </label>
                    <input
                      type="text"
                      name={`surname-${index}`}
                      value={guest.surname}
                      onChange={(e) => handleGuestChange(e, index)}
                      className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none"
                      placeholder="Soyadını giriniz"
                    />
                  </div>
                // </div>

                <div className="mt-4">
                  <label className="block text-gray-700 text-sm font-bold mb-2">
                    TC No veya Pasaport No
                  </label>
                  <input
                    type="text"
                    name={`id-${index}`}
                    value={guest.id}
                    onChange={(e) => handleGuestChange(e, index)}
                    className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none"
                    placeholder="TC No veya Pasaport No"
                  />
                </div>

                <div className="mt-4">
                  <label className="block text-gray-700 text-sm font-bold mb-2">
                    Doğum Tarihi
                  </label>
                  <input
                    type="date"
                    name={`dob-${index}`}
                    value={guest.dob}
                    onChange={(e) => handleGuestChange(e, index)}
                    className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none"
                  />
                </div>

                <div className="mt-4">
                  <label className="block text-gray-700 text-sm font-bold mb-2">
                    Cinsiyet
                  </label>
                  <div className="flex space-x-4">
                    <label className="inline-flex items-center">
                      <input
                        type="radio"
                        name={`gender-${index}`}
                        value="Kadın"
                        checked={guest.gender === "Kadın"}
                        onChange={(e) => handleGuestChange(e, index)}
                        className="form-radio"
                      />
                      <span className="ml-2">Kadın</span>
                    </label>
                    <label className="inline-flex items-center">
                      <input
                        type="radio"
                        name={`gender-${index}`}
                        value="Erkek"
                        checked={guest.gender === "Erkek"}
                        onChange={(e) => handleGuestChange(e, index)}
                        className="form-radio"
                      />
                      <span className="ml-2">Erkek</span>
                    </label>
                  </div>
                </div>
              </div>
            ))} */}

            <button
              onClick={handleNextStep}
              className="bg-[#ED7D31] text-white px-6 py-2 rounded-md hover:bg-[#d66924]"
            >
              Devam Et
            </button>
          </div>

          {/* Sağ Bölüm: Kart */}
          <div className="w-full md:w-1/3 md:ml-6 mt-8 md:mt-0">
            <div className="bg-white p-4 shadow-md rounded-lg">
              <img
                src={tourImage}
                alt="Klasik Kapadokya Turu"
                className="w-full h-[200px] object-cover rounded-lg"
              />
              <h3 className="text-xl font-semibold mt-4">
                Klasik Kapadokya Turu
              </h3>
              <div className="border-t border-gray-200 mt-2 pt-4">
                <h4 className="font-bold text-lg">Tur Detayları</h4>
                <p className="text-sm text-gray-600">
                  Tur Tarihleri: <strong>17 Ekim - 20 Ekim 2024</strong>
                </p>
                <p className="text-sm text-gray-600">Otel: Standart Oteller</p>
                <p className="text-sm text-gray-600">Misafir: 2 Yetişkin</p>
                <p className="text-sm text-gray-600">Vize: Yok</p>
              </div>
              <div className="border-t border-gray-200 mt-2 pt-4">
                <h4 className="font-bold text-lg">Ödeme Detayları</h4>
                <p className="text-sm text-gray-600">Tur Bedeli: 9.998,00 TL</p>
                <p className="text-sm text-green-500">
                  Tur İndirimi: -1.000,00 TL
                </p>
                <h3 className="text-xl font-bold">
                  Ödeyeceğiniz Tutar: 8.998,00 TL
                </h3>
              </div>
              <button className="mt-4 w-full bg-[#ED7D31] text-white py-2 rounded-md hover:bg-[#d66924]">
                Ödeme Seçenekleri
              </button>
            </div>
            <div className="w-full mt-8 md:mt-5">
              <div className="bg-white p-4 shadow-md rounded-lg">
                <h3>Tura Dahil Olan Hizmetler:</h3>
                <p>Ulaşım</p>
                <p>Yemek</p>
                <p>Konaklama</p>
              </div>
            </div>
            <div className="w-full mt-8 md:mt-5">
              <div className="bg-white p-4 shadow-md rounded-lg">
                <h3>Tura Dahil Olmayan Hizmetler:</h3>
                <p>Öğle yemeği</p>
                <p>Kişisel Harcamalar</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Reservation;
