import React from "react";
import Header from "../../components/Header";
import HeroSection from "../../components/HeroSection";
import BookingForm from "../../components/BookingForm";
import CategorySection from "../../components/CategorySection";
import Destinations from "../../components/Destinations";
import Tours from "../../components/Tours";
import TestimonialArea from "../../components/TestimonialArea";
import BlogsSection from "../../components/BlogsSection";
import Footer from "../../components/Footer";
import SpeedDial from "../../components/SpeedDial";
import ContactModal from "../../components/ContactModal";

const Home = () => {
  return (
    <div>
      <Header />
      <HeroSection />
      <BookingForm />
      <CategorySection />
      <Destinations />
      <Tours />
      <TestimonialArea />
      <BlogsSection />
      <Footer />
      <SpeedDial />
      <ContactModal />
    </div>
  );
};

export default Home;
