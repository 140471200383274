import React from "react";
import { FaSearch } from "react-icons/fa";

const BookingForm = () => {
  return (
    <div className="mt-4 bg-[#ED7D31] py-4 px-8 shadow-lg rounded-lg ml-[7%] w-[86%]">
      <form className="booking-form flex flex-wrap justify-between items-center gap-4">
        {/* Destination */}
        <div className="form-group flex-1 min-w-[100px]">
          <label className="block text-sm text-white mb-1">Destination</label>
          <select className="form-select block w-full border-none bg-white rounded p-2 text-[#4F4A45] focus:outline-none focus:ring-2 focus:ring-[#F6F1EE]">
            <option value="Select Destination" selected disabled>
              Select Destination
            </option>
            <option value="Australia">Australia</option>
            <option value="Dubai">Dubai</option>
            <option value="England">England</option>
            <option value="Sweden">Sweden</option>
            <option value="Thailand">Thailand</option>
            <option value="Egypt">Egypt</option>
            <option value="Saudi Arab">Saudi Arab</option>
            <option value="Switzerland">Switzerland</option>
            <option value="Scandinavia">Scandinavia</option>
            <option value="Western Europe">Western Europe</option>
            <option value="Indonesia">Indonesia</option>
            <option value="Italy">Italy</option>
          </select>
        </div>

        {/* Type */}
        <div className="form-group flex-1 min-w-[100px]">
          <label className="block text-sm text-white mb-1">Type</label>
          <select className="form-select block w-full border-none bg-white rounded p-2 text-[#4F4A45] focus:outline-none focus:ring-2 focus:ring-[#F6F1EE]">
            <option value="Adventure" selected disabled>
              Adventure
            </option>
            <option value="Beach">Beach</option>
            <option value="Group Tour">Group Tour</option>
            <option value="Couple Tour">Couple Tour</option>
            <option value="Family Tour">Family Tour</option>
          </select>
        </div>

        {/* Duration ve Tour Category yan yana */}
        <div className="flex flex-wrap gap-4 w-full md:w-auto">
          <div className="form-group flex-1 min-w-[100px]">
            <label className="block text-sm text-white mb-1">Duration</label>
            <select className="form-select block w-full border-none bg-white rounded p-2 text-[#4F4A45] focus:outline-none focus:ring-2 focus:ring-[#F6F1EE]">
              <option value="Normal" selected disabled>
                Duration
              </option>
              <option value="1">1 day</option>
              <option value="2">2 days</option>
              <option value="3">3 days</option>
              <option value="4">4 days</option>
              <option value="5">5 days</option>
              <option value="6">6 days</option>
              <option value="7">7 days</option>
            </select>
          </div>

          <div className="form-group flex-1 min-w-[200px]">
            <label className="block text-sm text-white mb-1">
              Tour Category
            </label>
            <select className="form-select block w-full border-none bg-white rounded p-2 text-[#4F4A45] focus:outline-none focus:ring-2 focus:ring-[#F6F1EE]">
              <option value="Luxury" selected disabled>
                Luxury
              </option>
              <option value="Deluxe">Deluxe</option>
              <option value="Economy">Economy</option>
            </select>
          </div>
        </div>

        {/* Search Button */}
        <div className="form-btn mt-2 w-full md:w-auto">
          <button className="bg-[#4F4A45] text-white px-4 py-2 rounded-full flex items-center justify-center transition-all duration-300 hover:bg-[#F6F1EE] hover:text-[#ED7D31] w-full">
            <FaSearch />
          </button>
        </div>
      </form>
    </div>
  );
};

export default BookingForm;
