import React, { useState } from "react";
import {
  FaPhoneAlt,
  FaTelegramPlane,
  FaEnvelope,
  FaWhatsapp,
} from "react-icons/fa";

const SpeedDial = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDial = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="fixed bottom-6 left-6 z-50">
      {/* Speed Dial Menu */}
      <div
        className={`flex flex-col items-center space-y-3 mb-4 ${
          isOpen ? "block" : "hidden"
        }`}
      >
        <button
          type="button"
          className="flex justify-center items-center w-12 h-12 text-gray-500 hover:text-gray-900 bg-white rounded-full border border-gray-200 shadow-sm hover:bg-gray-50 focus:ring-4 focus:ring-gray-300"
          aria-label="Phone"
        >
          <FaPhoneAlt className="w-5 h-5" />
        </button>
        <button
          type="button"
          className="flex justify-center items-center w-12 h-12 text-gray-500 hover:text-gray-900 bg-white rounded-full border border-gray-200 shadow-sm hover:bg-gray-50 focus:ring-4 focus:ring-gray-300"
          aria-label="Email"
        >
          <FaEnvelope className="w-5 h-5" />
        </button>
        <button
          type="button"
          className="flex justify-center items-center w-12 h-12 text-gray-500 hover:text-gray-900 bg-white rounded-full border border-gray-200 shadow-sm hover:bg-gray-50 focus:ring-4 focus:ring-gray-300"
          aria-label="Telegram"
        >
          <FaTelegramPlane className="w-5 h-5" />
        </button>
        <button
          type="button"
          className="flex justify-center items-center w-12 h-12 text-gray-500 hover:text-gray-900 bg-white rounded-full border border-gray-200 shadow-sm hover:bg-gray-50 focus:ring-4 focus:ring-gray-300"
          aria-label="WhatsApp"
        >
          <FaWhatsapp className="w-5 h-5" />
        </button>
      </div>

      {/* Toggle Button */}
      <button
        type="button"
        onClick={toggleDial}
        className="flex items-center justify-center w-14 h-14 text-white bg-[#ED7D31] rounded-full hover:bg-[#FF8A33] focus:ring-4 focus:ring-[#FFD09B]"
        aria-label="Open actions menu"
      >
        <svg
          className={`w-6 h-6 transition-transform ${
            isOpen ? "rotate-45" : "rotate-0"
          }`}
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 18 18"
        >
          <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M9 1v16M1 9h16"
          />
        </svg>
      </button>
    </div>
  );
};

export default SpeedDial;
