import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import { EffectCoverflow, Pagination } from "swiper/modules";
import { useNavigate } from "react-router-dom";

const Destinations = () => {
  const navigate = useNavigate();
  const destinations = [
    {
      name: "Destination 1",
      image: "https://swiperjs.com/demos/images/nature-1.jpg",
      tours: 5,
    },
    {
      name: "Destination 2",
      image: "https://swiperjs.com/demos/images/nature-2.jpg",
      tours: 3,
    },
    {
      name: "Destination 3",
      image: "https://swiperjs.com/demos/images/nature-3.jpg",
      tours: 7,
    },
    {
      name: "Destination 4",
      image: "https://swiperjs.com/demos/images/nature-4.jpg",
      tours: 4,
    },
    {
      name: "Destination 5",
      image: "https://swiperjs.com/demos/images/nature-5.jpg",
      tours: 6,
    },
    {
      name: "Destination 6",
      image: "https://swiperjs.com/demos/images/nature-6.jpg",
      tours: 8,
    },
    {
      name: "Destination 7",
      image: "https://swiperjs.com/demos/images/nature-7.jpg",
      tours: 2,
    },
    {
      name: "Destination 8",
      image: "https://swiperjs.com/demos/images/nature-8.jpg",
      tours: 9,
    },
    {
      name: "Destination 9",
      image: "https://swiperjs.com/demos/images/nature-9.jpg",
      tours: 5,
    },
  ];

  return (
    <section className="py-8 mx-1">
      <div className="container mx-auto px-4">
        <div className="text-center mb-8">
          <h2 className="text-3xl font-bold">Popular Destinations</h2>
          <p className="text-lg text-gray-600 mt-4 text-left">
            Discover some of the most stunning and sought-after destinations
            that promise unforgettable experiences. Whether you're looking for a
            relaxing beach getaway, an adventurous hiking trip, or an exotic
            cultural journey, our popular destinations offer a variety of tours
            tailored to suit every traveler's preferences. Let your wanderlust
            guide you to explore unique landscapes, vibrant cities, and hidden
            gems around the world. Each destination has been carefully selected
            to ensure you get the best of nature, history, and local culture.
          </p>
        </div>
        <Swiper
          effect={"coverflow"}
          grabCursor={false} // grabCursor'u devre dışı bırakıyoruz
          centeredSlides={true}
          slidesPerView={"auto"}
          loop={true}
          spaceBetween={10} // Kartlar arasındaki boşluk
          coverflowEffect={{
            rotate: 0,
            stretch: 0,
            depth: 100,
            modifier: 0.5,
            slideShadows: true,
          }}
          modules={[EffectCoverflow, Pagination]}
          className="mySwiper"
        >
          {destinations.map((destination, index) => (
            <SwiperSlide
              onClick={() => navigate("/tour-list")}
              key={index}
              className="bg-cover bg-center w-[280px] h-[400px] transform transition-transform hover:scale-105"
            >
              <div className="relative w-full h-full">
                <img
                  src={destination.image}
                  alt={destination.name}
                  className="w-full h-full object-cover rounded-lg"
                />
                <div className="absolute bottom-0 left-0 w-full bg-black bg-opacity-50 text-white p-4 rounded-b-lg">
                  <h3 className="font-semibold text-lg">{destination.name}</h3>
                  <p className="text-sm">{destination.tours} Tours Available</p>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </section>
  );
};

export default Destinations;
