import React from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";

const TermsOfService = () => {
  return (
    <div>
      <Header />
      {/* Main Content */}
      <div className="container mx-auto px-4 py-16 mt-4">
        <h1 className="text-3xl font-bold mb-8 text-[#4F4A45]">
          Terms of Service
        </h1>
        <div className="space-y-6 text-gray-700">
          <section>
            <h2 className="text-xl font-semibold mb-2">1. Introduction</h2>
            <p>
              Welcome to Travok Travel. By using our services, you agree to
              comply with our Terms of Service. Please read these terms
              carefully before using our website.
            </p>
          </section>
          <section>
            <h2 className="text-xl font-semibold mb-2">
              2. User Responsibilities
            </h2>
            <p>
              As a user, you are responsible for your own actions and the
              content you post. You agree not to use our platform for any
              unlawful activities.
            </p>
          </section>
          <section>
            <h2 className="text-xl font-semibold mb-2">
              3. Service Limitations
            </h2>
            <p>
              We strive to keep our services available at all times, but we do
              not guarantee uninterrupted access. Service disruptions may occur
              due to maintenance or technical issues.
            </p>
          </section>
          <section>
            <h2 className="text-xl font-semibold mb-2">4. Privacy Policy</h2>
            <p>
              Your privacy is important to us. Please refer to our Privacy
              Policy for details on how we collect and use your information.
            </p>
          </section>
          <section>
            <h2 className="text-xl font-semibold mb-2">5. Changes to Terms</h2>
            <p>
              We may update these Terms of Service from time to time. When
              changes are made, the updated version will be posted on our
              website, and the date of the latest revision will be indicated.
            </p>
          </section>
          <section>
            <h2 className="text-xl font-semibold mb-2">
              6. Contact Information
            </h2>
            <p>
              If you have any questions about these terms, please contact us at{" "}
              <a
                href="mailto:support@travoktravel.com"
                className="text-blue-600 hover:underline"
              >
                support@travoktravel.com
              </a>
              .
            </p>
          </section>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default TermsOfService;
