import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import drinks from "../assets/img/drinks.jpg";

const BlogsSection = () => {
  const navigate = useNavigate();
  const [blogs, setBlogs] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // API'den popüler blogları getir
    const fetchPopularBlogs = async () => {
      try {
        const response = await axios.get(
          "https://travoktravel.com/api/v1/blog/getPopularBlogs"
        );
        if (response.data.success) {
          setBlogs(response.data.data);
        } else {
          alert("Popüler bloglar yüklenemedi.");
        }
      } catch (error) {
        console.error("API isteği başarısız:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchPopularBlogs();
  }, []);

  const handleViewMore = () => {
    navigate("/blog-list");
  };

  const handleNavigateToBlogDetail = (blogId) => {
    navigate(`/blog-detail/${blogId}`);
  };

  return (
    <section className="py-16">
      <div className="container mx-auto px-4">
        <div className="mb-6">
          <h2 className="text-3xl font-bold mb-2 text-center">
            News & Articles From Tourm
          </h2>
          <p className="text-gray-600 text-left">
            Stay updated with the latest travel news and insightful articles.
            Explore travel tips, destination guides, and recommendations to make
            your next trip unforgettable.
          </p>
        </div>
        <div className="text-right mt-6 px-4">
          <button
            onClick={handleViewMore}
            className="bg-[#ED7D31] text-white px-6 py-2 rounded-md text-lg transition duration-300 hover:bg-white hover:text-[#ED7D31] border border-[#ED7D31]"
          >
            View More
          </button>
        </div>

        {isLoading ? (
          <p>Yükleniyor...</p>
        ) : (
          <Swiper
            spaceBetween={20}
            slidesPerView={4}
            modules={[Pagination]}
            className="mySwiper px-4 py-8"
            breakpoints={{
              320: {
                slidesPerView: 1,
                spaceBetween: 10,
              },
              640: {
                slidesPerView: 2,
                spaceBetween: 15,
              },
              768: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              1024: {
                slidesPerView: 3,
                spaceBetween: 20,
              },
              1280: {
                slidesPerView: 4,
                spaceBetween: 30,
              },
            }}
          >
            {blogs.map((blog) => (
              <SwiperSlide
                key={blog._id}
                onClick={() => handleNavigateToBlogDetail(blog._id)}
                className="bg-white rounded-2xl shadow-lg flex flex-col items-center overflow-hidden cursor-pointer transform transition-transform hover:scale-105"
              >
                <div className="w-full h-48 overflow-hidden rounded-t-2xl">
                  <img
                    src={drinks} // Varsayılan görsel, API'den özel görsel yoksa kullanılacak
                    alt={blog.title}
                    className="w-full h-full object-cover"
                  />
                </div>
                <div className="p-4 text-center w-full">
                  <p className="text-gray-500 text-sm">
                    {new Date(blog.createdAt).toLocaleDateString("en-US", {
                      month: "long",
                      day: "2-digit",
                      year: "numeric",
                    })}{" "}
                    | 5 Min Read
                  </p>
                  <h3 className="text-lg font-semibold text-gray-700 mt-2">
                    {blog.title}
                  </h3>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        )}
      </div>
    </section>
  );
};

export default BlogsSection;
