// src/App.jsx
import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop";
import Home from "./pages/Home/Home";
import TourList from "./pages/Tour/TourList";
import TourDetail from "./pages/Tour/TourDetail";
import Register from "./pages/Auth/Register";
import Login from "./pages/Auth/Login";
import BlogDetail from "./pages/Blog/BlogDetail";
import BlogList from "./pages/Blog/BlogList";
import Reservation from "./pages/Cart/Reservation";
import Contact from "./pages/Contact/Contact";
import Service from "./pages/Services/Service";
import About from "./pages/About/About";
import Faq from "./pages/Faq/Faq";
import Payment from "./pages/Cart/Payment";
import Activities from "./pages/Activities/Activities";
import TermsOfService from "./pages/Services/TermsOfService";
import Profile from "./pages/Profile/Profile";

function App() {
  return (
    <Router>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/tour-list" element={<TourList />} />
        <Route path="/tour-detail/:tourId" element={<TourDetail />} />
        <Route path="/register" element={<Register />} />
        <Route path="/login" element={<Login />} />
        <Route path="/blog-detail/:blogId" element={<BlogDetail />} />
        <Route path="/blog-list" element={<BlogList />} />
        <Route path="/reservation" element={<Reservation />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/service" element={<Service />} />
        <Route path="/about" element={<About />} />
        <Route path="/faq" element={<Faq />} />
        <Route path="/payment" element={<Payment />} />
        <Route path="/activities" element={<Activities />} />
        <Route path="/terms-of-service" element={<TermsOfService />} />
        <Route path="/profile" element={<Profile />} />
      </Routes>
    </Router>
  );
}

export default App;
