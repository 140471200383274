import React, { useState } from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import alanyaImage from "../../assets/img/alanya.jpg";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";

const activities = [
  {
    title: "Scuba Diving",
    listings: 15,
    description:
      "Experience the underwater world with our professional diving instructors. Explore marine life and beautiful underwater landscapes.",
  },
  {
    title: "Mountain Hiking",
    listings: 20,
    description:
      "Enjoy a challenging hike through scenic mountain trails. Perfect for adventure seekers who love nature.",
  },
  {
    title: "City Bike Tour",
    listings: 10,
    description:
      "Discover the city's landmarks and hidden gems with our guided bike tours.",
  },
  {
    title: "Sunset Cruise",
    listings: 8,
    description:
      "Relax on a sunset cruise and enjoy the picturesque views as the sun sets over the horizon.",
  },
  {
    title: "Paragliding",
    listings: 12,
    description:
      "Feel the adrenaline rush as you paraglide over stunning landscapes.",
  },
];

const Activities = () => {
  const [selectedActivity, setSelectedActivity] = useState(null);

  const openModal = (activity) => {
    setSelectedActivity(activity);
  };

  const closeModal = () => {
    setSelectedActivity(null);
  };

  return (
    <div>
      {/* Header */}
      <Header />

      {/* Banner Image */}
      <div className="w-full h-[400px] relative">
        <img
          src={alanyaImage}
          alt="Activities"
          className="w-full h-full object-cover"
        />
        <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <h1 className="text-3xl md:text-4xl font-bold text-white">
            Our Activities
          </h1>
        </div>
      </div>

      {/* Introduction Paragraph */}
      <div className="container mx-auto py-2 mt-4 px-4 text-left">
        <p className="text-lg text-gray-700">
          At Travok Travel, we offer a variety of exciting activities that cater
          to all types of travelers. Whether you're looking for an adrenaline
          rush or a relaxing experience, we have activities that will make your
          trip unforgettable. Explore our activities and make the most of your
          journey.
        </p>
      </div>

      {/* Activities Section */}
      <div className="container mx-auto py-8 px-4">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-8">
          {activities.map((activity, index) => (
            <div
              key={index}
              className="bg-white shadow-lg rounded-lg overflow-hidden"
            >
              {/* Image */}
              <div className="w-full h-[150px]">
                <img
                  src={alanyaImage}
                  alt={activity.title}
                  className="w-full h-full object-cover"
                />
              </div>
              {/* Content */}
              <div className="bg-[#fff] p-2 text-center">
                <h3 className="text-lg font-bold">{activity.title}</h3>
                <button
                  onClick={() => openModal(activity)}
                  className="border border-[#ED7D31] text-[#ED7D31] rounded-lg px-4 py-2 hover:bg-[#ED7D31] hover:text-white transition-colors"
                >
                  View Details
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Modal */}
      {selectedActivity && (
        <div
          id="default-modal"
          tabIndex="-1"
          aria-hidden="true"
          className="fixed top-0 left-0 right-0 z-50 flex justify-center items-center w-full h-full bg-black bg-opacity-50"
        >
          <div className="relative p-4 w-full max-w-2xl">
            <div className="relative bg-[#4F4A45] rounded-lg shadow">
              {/* Modal header */}
              <div className="flex items-center justify-between p-4 border-b rounded-t dark:border-gray-600">
                <h3 className="text-xl font-semibold text-[#ED7D31]">
                  {selectedActivity.title}
                </h3>
                <button
                  type="button"
                  className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                  onClick={closeModal}
                >
                  <svg
                    className="w-3 h-3"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 14 14"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                    />
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
              </div>
              {/* Modal body */}
              <div className="p-6 space-y-6">
                <Swiper className="mySwiper">
                  <SwiperSlide>
                    <img
                      src={alanyaImage}
                      alt="Activity Image 1"
                      className="w-full h-[200px] object-cover rounded-lg"
                    />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img
                      src={alanyaImage}
                      alt="Activity Image 2"
                      className="w-full h-[200px] object-cover rounded-lg"
                    />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img
                      src={alanyaImage}
                      alt="Activity Image 3"
                      className="w-full h-[200px] object-cover rounded-lg"
                    />
                  </SwiperSlide>
                </Swiper>
                <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                  {selectedActivity.description}
                </p>
              </div>
              {/* Modal footer */}
              <div className="flex items-center p-6 rounded-b dark:border-gray-600"></div>
            </div>
          </div>
        </div>
      )}

      {/* Footer */}
      <Footer />
    </div>
  );
};

export default Activities;
