import React, { useState, useEffect, useRef } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import logo from "../assets/img/logo.png";
import userAvatar from "../assets/img/alanya.jpg"; // Kullanıcı avatarı için örnek resim

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false); // Dropdown için state
  const dropdownRef = useRef(null); // Dropdown menüsü için referans
  const avatarButtonRef = useRef(null); // Avatar butonu için referans
  const navigate = useNavigate();
  // localStorage'dan kullanıcı bilgilerini al
  const user = JSON.parse(localStorage.getItem("user"));
  const [userName, setUserName] = useState(user?.name || "Guest User");
  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleRegisterClick = () => {
    navigate("/register");
    setDropdownOpen(false); // Menü kapat
  };

  const handleLoginClick = () => {
    navigate("/login");
    setDropdownOpen(false); // Menü kapat
  };

  // Dropdown menüsünü dışarıya tıklanınca kapatmak için
  useEffect(() => {
    const handleClickOutside = (event) => {
      // Eğer tıklanan yer dropdown menüsü ya da avatar butonu değilse dropdown'u kapat
      if (
        dropdownRef.current &&
        avatarButtonRef.current &&
        !dropdownRef.current.contains(event.target) &&
        !avatarButtonRef.current.contains(event.target)
      ) {
        setDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleLogout = async () => {
    // localStorage'dan kullanıcı bilgilerini sil
    localStorage.removeItem("user");
    setDropdownOpen(false); // Menüyü kapat
    navigate("/login");
  }

  return (
    <header className="fixed top-0 left-0 w-full bg-[#4F4A45] z-40 shadow-md">
      {/* Main Menu */}
      <div className="py-4 px-4 sticky top-0 bg-[#F6F1EE] shadow-md">
        <div className="container mx-auto flex justify-between items-center">
          {/* Logo */}
          <a href="/" className="flex items-center space-x-2">
            <img src={logo} alt="Logo" className="w-25 h-10 object-contain" />
          </a>

          {/* Navigation + Avatar + Mobile Menu in one flex container */}
          <div className="flex items-center space-x-4">
            {/* Navigation */}
            <nav className="hidden xl:flex space-x-8">
              <a href="/" className="text-[#ED7D31] hover:text-[#4F4A45] py-2">
                Home
              </a>
              <a
                href="/service"
                className="text-[#ED7D31] hover:text-[#4F4A45] py-2"
              >
                Services
              </a>
              <a
                href="/blog-list"
                className="text-[#ED7D31] hover:text-[#4F4A45] py-2"
              >
                Blogs
              </a>
              <a
                href="/activities"
                className="text-[#ED7D31] hover:text-[#4F4A45] py-2"
              >
                Activities
              </a>
              <a
                href="/contact"
                className="text-[#ED7D31] hover:text-[#4F4A45] py-2"
              >
                Contact Us
              </a>
            </nav>

            {/* Avatar Dropdown */}
            <div className="relative" ref={dropdownRef}>
              <button
                onClick={toggleDropdown}
                className="flex items-center text-sm bg-gray-800 rounded-full focus:ring-4 focus:ring-[#ED7D31]"
                type="button"
                ref={avatarButtonRef} // Avatar butonuna referans veriyoruz
              >
                <img
                  className="w-8 h-8 rounded-full"
                  src={userAvatar}
                  alt="User Avatar"
                />
              </button>

              {dropdownOpen && (
                <div className="absolute right-0 mt-2 w-48 bg-[#ED7D31] rounded-lg shadow-lg z-50">
                  <div
                    onClick={() => navigate("/profile")}
                    className="px-4 py-3 text-sm text-white hover:bg-[#F6F1EE] rounded-lg hover:text-[#ED7D31] cursor-pointer"
                  >
                    {user ? (
                      <div>
                        {user.name} {user.surname}
                      </div>
                    ) : (
                      <div>Guest User</div>
                    )}
                  </div>
                  <ul className="py-0 text-sm text-gray-200">
                    {user ? (
                      <div>
                        <li
                          onClick={handleLogout}
                          className="px-4 py-3 hover:bg-[#F6F1EE] hover:text-[#ED7D31] cursor-pointer"
                        >
                          Logout
                        </li>
                      </div>
                    ) : (
                      <div>
                        <li
                          onClick={handleRegisterClick}
                          className="px-4 py-3 hover:bg-[#F6F1EE] hover:text-[#ED7D31] cursor-pointer"
                        >
                          Register
                        </li>
                        <li
                          onClick={handleLoginClick}
                          className="px-4 py-3 hover:bg-[#F6F1EE] hover:text-[#ED7D31] cursor-pointer"
                        >
                          Login
                        </li>
                      </div>
                    )}
                  </ul>
                </div>
              )}
            </div>

            {/* Mobile Menu Toggle */}
            <button
              className="xl:hidden text-gray-600 focus:outline-none"
              onClick={toggleMenu}
            >
              {menuOpen ? <FaTimes size={24} /> : <FaBars size={24} />}
            </button>
          </div>
        </div>

        {/* Mobile Menu */}
        <div
          className={`xl:hidden transition-all duration-300 ease-in-out ${
            menuOpen ? "max-h-screen" : "max-h-0"
          } overflow-hidden`}
        >
          <nav className="flex flex-col space-y-4 mt-4 bg-white shadow-lg py-4 px-4">
            <a href="/" className="text-gray-700 hover:text-blue-500">
              Home
            </a>
            <a href="/service" className="text-gray-700 hover:text-blue-500">
              Services
            </a>
            <a href="/activities" className="text-gray-700 hover:text-blue-500">
              Activities
            </a>
            <a href="/contact" className="text-gray-700 hover:text-blue-500">
              Contact Us
            </a>
            <button className="px-6 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600">
              Book Now
            </button>
          </nav>
        </div>
      </div>
    </header>
  );
};

export default Header;
