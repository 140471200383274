import React from "react";
import Header from "../../components/Header";
import contactBanner from "../../assets/img/alanya.jpg"; // Geniş görsel için placeholder
import Footer from "../../components/Footer";

const About = () => {
  return (
    <div>
      {/* Header */}
      <Header />

      {/* Banner Image */}
      <div className="w-full h-[400px] relative">
        {/* Header'ın altında boşluk bırakmak için margin-top ayarı */}
        <img
          src={contactBanner}
          alt="Contact Us"
          className="w-full h-full object-cover"
        />
        <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <h1 className="text-3xl md:text-4xl font-bold text-white">
            About Us
          </h1>
        </div>
      </div>

      {/* New Animated Section */}
      <div className="flex flex-col md:flex-row items-center justify-between py-16 px-4 md:px-8">
        {/* Left Side - Animated Images */}
        <div className="relative w-full md:w-1/2 h-auto flex justify-center items-center space-x-4">
          {/* Main Image */}
          <img
            src={contactBanner} // Use alanya.jpg here
            alt="Alanya"
            className="w-[300px] h-[250px] object-cover rounded-lg shadow-lg transform hover:scale-105 transition-all duration-500 ease-in-out"
          />

          {/* Top Left Image */}
          <div className="absolute -left-1 top-8">
            <img
              src={contactBanner} // Use alanya.jpg here
              alt="Alanya"
              className="w-[150px] h-[150px] object-cover rounded-lg shadow-lg transform hover:scale-105 transition-all duration-500 ease-in-out"
            />
          </div>

          {/* Bottom Right Image with X-axis animation */}
          <div className="absolute right-0 bottom-8 animate-slideX">
            <img
              src={contactBanner} // Use alanya.jpg here
              alt="Alanya"
              className="w-[150px] h-[150px] object-cover rounded-lg shadow-lg transform hover:scale-105 transition-all duration-500 ease-in-out"
            />
          </div>
        </div>

        {/* Right Side - Text Content */}
        <div className="mt-8 md:mt-0 md:ml-12 w-full md:w-1/2">
          <h2 className="text-3xl md:text-4xl font-bold mb-4">
            Welcome To Travel
          </h2>
          <p className="text-lg text-gray-600 mb-4">
            We are world-reputed travel agency. There are many variations of
            passages available, but the majority have suffered alteration in
            some form, by injected humor randomised words.
          </p>
          <ul className="space-y-4">
            <li className="flex items-start">
              <span className="text-teal-500 mr-4">•</span>
              <span>
                Exclusive Trip: There are many variations of passages available
                but the majority.
              </span>
            </li>
            <li className="flex items-start">
              <span className="text-teal-500 mr-4">•</span>
              <span>
                Safety First Always: There are many variations of passages
                available but the majority.
              </span>
            </li>
            <li className="flex items-start">
              <span className="text-teal-500 mr-4">•</span>
              <span>
                Professional Guide: There are many variations of passages
                available but the majority.
              </span>
            </li>
          </ul>
          <button className="mt-6 bg-teal-500 hover:bg-teal-600 text-white font-bold py-3 px-6 rounded-lg shadow-lg">
            Contact With Us
          </button>
        </div>
      </div>

      {/* JSX Style for the animation */}
      <style jsx>{`
        @keyframes slideX {
          0% {
            transform: translateX(0);
          }
          50% {
            transform: translateX(20px);
          }
          100% {
            transform: translateX(0);
          }
        }

        .animate-slideX {
          animation: slideX 3s ease-in-out infinite;
        }
      `}</style>

      <section className="bg-white shadow-md m-4 rounded-lg py-2 px-4 mx-auto max-w-screen-xl lg:py-6 lg:px-6">
        <div className="mx-auto max-w-screen-sm text-center mb-8 lg:mb-16">
          <h2 className="mb-1 text-4xl tracking-tight font-extrabold text-gray-900">
            Our Team
          </h2>
          <p className="font-light text-gray-500 lg:mb-16 sm:text-xl">
            Meet our talented team of professionals who make everything
            possible.
          </p>
        </div>

        <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-3">
          <div className="items-center bg-[#F6F1EE] rounded-lg shadow sm:flex dark:border-gray-700">
            <div>
              <img
                className="w-full rounded-lg sm:rounded-none sm:rounded-l-lg"
                src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/avatars/bonnie-green.png"
                alt="Bonnie Avatar"
              />
            </div>

            <div className="mx-2">
              <h3 className="text-xl font-bold tracking-tight text-[#ED7D31]">
                <a href="#">Bonnie Green</a>
              </h3>
              <span className="text-gray-500 dark:text-gray-700">
                CEO & Web Developer
              </span>
              <p className="mt-3 mb-4 font-light text-gray-500">
                Bonnie drives the technical strategy of the platform and brand.
              </p>
              <ul className="flex space-x-4 sm:mt-0">
             
              </ul>
            </div>
          </div>

          <div className="items-center bg-[#F6F1EE] rounded-lg shadow sm:flex dark:border-gray-700">
            <div>
              <img
                className="w-full rounded-lg sm:rounded-none sm:rounded-l-lg"
                src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/avatars/bonnie-green.png"
                alt="Bonnie Avatar"
              />
            </div>

            <div className="mx-2">
              <h3 className="text-xl font-bold tracking-tight text-[#ED7D31]">
                <a href="#">Bonnie Green</a>
              </h3>
              <span className="text-gray-500 dark:text-gray-700">
                CEO & Web Developer
              </span>
              <p className="mt-3 mb-4 font-light text-gray-500">
                Bonnie drives the technical strategy of the platform and brand.
              </p>
              <ul className="flex space-x-4 sm:mt-0">
             
              </ul>
            </div>
          </div>

          <div className="items-center bg-[#F6F1EE] rounded-lg shadow sm:flex dark:border-gray-700">
            <div>
              <img
                className="w-full rounded-lg sm:rounded-none sm:rounded-l-lg"
                src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/avatars/bonnie-green.png"
                alt="Bonnie Avatar"
              />
            </div>

            <div className="mx-2">
              <h3 className="text-xl font-bold tracking-tight text-[#ED7D31]">
                <a href="#">Bonnie Green</a>
              </h3>
              <span className="text-gray-500 dark:text-gray-700">
                CEO & Web Developer
              </span>
              <p className="mt-3 mb-4 font-light text-gray-500">
                Bonnie drives the technical strategy of the platform and brand.
              </p>
              <ul className="flex space-x-4 sm:mt-0">
             
              </ul>
            </div>
          </div>

          <div className="items-center bg-[#F6F1EE] rounded-lg shadow sm:flex dark:border-gray-700">
            <div>
              <img
                className="w-full rounded-lg sm:rounded-none sm:rounded-l-lg"
                src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/avatars/bonnie-green.png"
                alt="Bonnie Avatar"
              />
            </div>

            <div className="mx-2">
              <h3 className="text-xl font-bold tracking-tight text-[#ED7D31]">
                <a href="#">Bonnie Green</a>
              </h3>
              <span className="text-gray-500 dark:text-gray-700">
                CEO & Web Developer
              </span>
              <p className="mt-3 mb-4 font-light text-gray-500">
                Bonnie drives the technical strategy of the platform and brand.
              </p>
              <ul className="flex space-x-4 sm:mt-0">
             
              </ul>
            </div>
          </div>
        </div>
      </section>

      {/* Footer */}
      <Footer />
    </div>
  );
};

export default About;
