import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Navigation, Pagination } from "swiper/modules";
import camp from "../assets/img/camp.jpg"; // Placeholder image
import { useNavigate } from "react-router-dom";
import axios from "axios";

const CategorySection = () => {
  const [categories, setCategories] = useState([]);
  const navigate = useNavigate();

  // Fetch categories from the API
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get(
          "https://travoktravel.com/api/v1/category/getAllCategories"
        );
        if (response.data.success && response.data.data) {
          // Map data to match component structure
          const formattedCategories = response.data.data.map((category) => ({
            name: category.displayName || category.name,
            image: camp, // Use camp image as a placeholder
            tours: Math.floor(Math.random() * 20) + 1, // Random number for tours count
          }));
          setCategories(formattedCategories);
        }
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchCategories();
  }, []);

  // Handle slide click to navigate
  const handleSlideClick = (category) => {
    navigate("/tour-list", { state: { category } });
  };

  return (
    <div className="tours-section py-1 mt-4">
      <div className="container mx-auto px-4 py-2">
        <div className="mb-6 text-center">
          <h2 className="text-3xl font-bold mb-2">Tour Categories</h2>
          <p className="text-gray-600 text-left">
            Explore a variety of tour categories to suit your adventure needs,
            from wildlife safaris and hiking to water sports and cultural
            experiences. Each category offers unique tours that guarantee an
            unforgettable journey.
          </p>
        </div>

        <Swiper
          spaceBetween={20}
          slidesPerView={4}
          modules={[Navigation, Pagination]}
          className="mySwiper px-4 py-2 pb-8"
          breakpoints={{
            320: { slidesPerView: 1, spaceBetween: 10 },
            640: { slidesPerView: 2, spaceBetween: 15 },
            768: { slidesPerView: 2, spaceBetween: 20 },
            1024: { slidesPerView: 3, spaceBetween: 20 },
            1280: { slidesPerView: 4, spaceBetween: 30 },
          }}
        >
          {categories.map((category, index) => (
            <SwiperSlide
              key={index}
              onClick={() => handleSlideClick(category.name)}
              className="bg-white rounded-2xl shadow-lg flex flex-col items-center cursor-pointer transform transition-transform hover:scale-105"
              style={{ height: "320px", width: "100%" }}
            >
              <div className="w-full h-48 overflow-hidden rounded-t-2xl">
                <img
                  src={category.image}
                  alt={category.name}
                  className="w-full h-full object-cover"
                />
              </div>
              <div className="text-center mt-2 p-4">
                <h3 className="font-semibold text-lg">{category.name}</h3>
                <p className="text-sm">{category.tours} Tours Available</p>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default CategorySection;
