import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Navigation, Pagination } from "swiper/modules";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const Tours = () => {
  const navigate = useNavigate();
  const [tours, setTours] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // API'den popüler turları getir
    const fetchPopularTours = async () => {
      try {
        const response = await axios.get(
          "https://travoktravel.com/api/v1/tour/getPopularTours"
        );
        if (response.data.success) {
          setTours(response.data.data);
        } else {
          alert("Popüler turlar yüklenemedi.");
        }
      } catch (error) {
        console.error("API isteği başarısız:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchPopularTours();
  }, []);

  const handleNavigateToTourDetail = (tourId) => {
    navigate(`/tour-detail/${tourId}`);
  };

  return (
    <div className="tours-section py-8">
      <div className="container mx-auto px-4">
        <div className="mb-6 text-center">
          <h2 className="text-3xl font-bold mb-2">Most Popular Tours</h2>
          <p className="text-gray-600">
            Explore our best-selling tour packages and embark on an
            unforgettable journey. Choose from a variety of carefully curated
            tours to suit your travel preferences.
          </p>
        </div>
        {isLoading ? (
          <p>Yükleniyor...</p>
        ) : (
          <Swiper
            spaceBetween={20}
            slidesPerView={4}
            modules={[Navigation, Pagination]}
            className="mySwiper px-4 py-8"
            breakpoints={{
              320: { slidesPerView: 1, spaceBetween: 10 },
              640: { slidesPerView: 2, spaceBetween: 15 },
              768: { slidesPerView: 2, spaceBetween: 20 },
              1024: { slidesPerView: 3, spaceBetween: 20 },
              1280: { slidesPerView: 4, spaceBetween: 30 },
            }}
          >
            {tours.map((tour) => (
              <SwiperSlide
                key={tour._id}
                onClick={() => handleNavigateToTourDetail(tour._id)}
                className="bg-white rounded-2xl shadow-lg transform transition-transform hover:scale-105 cursor-pointer"
              >
                <div className="w-full h-48 overflow-hidden rounded-t-2xl">
                  <img
                    src={tour.image || "https://placehold.co/300x200"} // Varsayılan görsel
                    alt={tour.title}
                    className="w-full h-full object-cover"
                  />
                </div>
                <div className="p-4 text-center">
                  <h3 className="text-lg font-semibold text-gray-700">
                    {tour.title}
                  </h3>
                  <div className="flex justify-center items-center mt-2">
                    <span className="text-yellow-500 text-sm">★</span>
                    <span className="text-gray-600 text-sm ml-2">
                      ({tour.ratingsAverage} Rating)
                    </span>
                  </div>
                  <p className="text-lg font-semibold text-gray-900 mt-2">
                    ${tour.priceTable[0]?.price || "N/A"}/Person
                  </p>
                  <div className="mt-2">
                    <span className="text-sm text-gray-500 flex items-center">
                      <i className="far fa-clock mr-1"></i> {tour.durationTitle}
                    </span>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        )}
      </div>
    </div>
  );
};

export default Tours;
