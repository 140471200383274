import React, { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";

import Header from "../../components/Header";
import Footer from "../../components/Footer";
import SpeedDial from "../../components/SpeedDial";

const TourDetail = () => {
  const { tourId } = useParams();
  const navigate = useNavigate();
  const [tour, setTour] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const closeModal = (e) => {
    if (e.target.id === "modal-background") {
      setIsModalOpen(false);
    }
  };

  useEffect(() => {
    const fetchTourDetail = async () => {
      try {
        const response = await axios.get(
          `https://travoktravel.com/api/v1/tour/getTourById/${tourId}`
        );
        setTour(response.data.data);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching tour details:", error);
      }
    };

    fetchTourDetail();
  }, [tourId]);

  if (isLoading) {
    return <p>Loading...</p>;
  }

  return (
    <>
      <Header />
      <SpeedDial />

      {/* Tour Title and Gallery */}
      <div className="container mx-auto px-4 md:px-6 mt-[120px]">
        <div className="w-full md:w-3/4 mx-auto">
          <h1 className="text-3xl md:text-4xl font-semibold text-[#4F4A45] text-left">
            {tour.title}
          </h1>
          <div className="relative mt-6">
            <img
              src={tour.image}
              alt={tour.title}
              className="w-full h-[300px] md:h-[500px] object-contain bg-[#F6F1EE] rounded-lg shadow-lg"
            />
            <button
              onClick={toggleModal}
              className="absolute bottom-4 left-4 text-white bg-[#6C5F5B] hover:bg-gray-500 font-medium rounded-lg text-sm px-5 py-2.5"
            >
              View Gallery
            </button>
          </div>
        </div>
      </div>

      {/* Tour Description */}
      <div className="container mx-auto py-8 px-4 md:px-6">
        <div className="w-full md:w-3/4 mx-auto">
          <p className="text-base md:text-lg text-gray-600 mt-4 text-left">
            {tour.description}
          </p>
        </div>
      </div>

      {/* Price Table */}
      <div className="container mx-auto py-8 px-4 md:px-6">
        <div className="w-full md:w-3/4 mx-auto">
          <h2 className="text-2xl md:text-3xl font-semibold mb-4 text-left">
            Prices
          </h2>
          <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 bg-gray-50 p-4 rounded-lg shadow-lg">
            {tour.priceTable.map((price) => (
              <div key={price._id} className="flex flex-col items-center">
                <h3 className="text-xl font-semibold text-gray-800">
                  {price.name.toUpperCase()}
                </h3>
                <p className="text-lg text-gray-600">{price.price}€</p>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Contact Reservation Form */}
      <div className="container mx-auto py-8 px-4 md:px-6">
        <div className="w-full md:w-3/4 mx-auto">
          <h2 className="text-2xl md:text-3xl font-semibold mb-4 text-left">
            Contact for Reservation
          </h2>
          <form className="space-y-4">
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
              <input
                type="text"
                placeholder="Name"
                className="w-full bg-gray-100 rounded-lg p-2.5 border border-gray-300"
              />
              <input
                type="text"
                placeholder="Surname"
                className="w-full bg-gray-100 rounded-lg p-2.5 border border-gray-300"
              />
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
              <input
                type="tel"
                placeholder="Phone Number"
                className="w-full bg-gray-100 rounded-lg p-2.5 border border-gray-300"
              />
              <input
                type="email"
                placeholder="Email"
                className="w-full bg-gray-100 rounded-lg p-2.5 border border-gray-300"
              />
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
              <input
                type="number"
                placeholder="Number of People"
                min="1"
                className="w-full bg-gray-100 rounded-lg p-2.5 border border-gray-300"
              />
              <input
                type="number"
                placeholder="Number of Children"
                min="0"
                className="w-full bg-gray-100 rounded-lg p-2.5 border border-gray-300"
              />
            </div>
            <button
              type="submit"
              className="w-full bg-[#ED7D31] text-white rounded-lg py-2.5 mt-4 hover:bg-orange-600 transition-all"
            >
              Submit Reservation Request
            </button>
          </form>
        </div>
      </div>

      {/* Difference Section */}
      <div className="container mx-auto py-8 px-4 md:px-6">
        <div className="w-full md:w-3/4 mx-auto">
          <h2 className="text-2xl md:text-3xl font-semibold mb-4 text-left">
            Why Choose Us?
          </h2>
          <p className="text-base md:text-lg text-gray-600">
            {tour.difference}
          </p>
        </div>
      </div>

      {/* Content Section */}
      <div className="container mx-auto py-8 px-4 md:px-6">
        <div className="w-full md:w-3/4 mx-auto shadow-lg rounded-lg pb-2">
          {tour.content.map((section, index) => (
            <div key={index} className="bg-white mb-4 p-6">
              {section.subtitle && (
                <h3 className="text-xl font-semibold text-gray-800 mb-3">
                  {section.subtitle}
                </h3>
              )}
              {section.image && (
                <div className="bg-red-300 rounded-md overflow-hidden mb-4">
                  <img
                    src={section.image}
                    alt={section.subtitle || "Content Image"}
                    className="w-full h-48 object-cover"
                  />
                </div>
              )}
              <p className="text-gray-600">{section.paragraph}</p>
            </div>
          ))}
        </div>
      </div>

      {/* Plan Section */}
      <div className="container mx-auto py-2 px-4 md:px-6">
        <div className="w-full md:w-3/4 mx-auto shadow-lg rounded-lg">
          <h2 className="text-2xl md:text-3xl ml-5 mt-4 font-semibold mb-1 text-left">
            Tour Program
          </h2>
          {tour.plan.map((day, index) => (
            <div key={index} className="bg-white rounded-lg mb-4 p-6">
              <h3 className="text-xl font-semibold text-gray-800 mb-3">
                {day.day} Program
              </h3>
              {day.details.map((activity, i) => (
                <div key={i} className="mb-2">
                  {activity.image && (
                    <div className="bg-red-300 rounded-md overflow-hidden mb-4">
                      <img
                        src={activity.image}
                        alt={`${activity.title} image`}
                        className="w-full h-48 object-cover"
                      />
                    </div>
                  )}
                  <h4 className="text-lg font-medium text-[#4F4A45]">
                    {activity.title}
                  </h4>
                  <p className="text-gray-600">{activity.description}</p>
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>

      {/* Testimonials Section */}
      {tour?.comments.length > 0 && (
        <div className="container mx-auto py-8 px-4 md:px-6">
          <div className="w-full md:w-3/4 mx-auto">
            <h2 className="text-2xl md:text-3xl font-semibold mb-4 text-left">
              Customer Reviews
            </h2>
            <Swiper
              slidesPerView={1}
              spaceBetween={30}
              loop={true}
              pagination={{ clickable: true }}
              className="mySwiper p-4"
            >
              {tour.comments.map((comment) => (
                <SwiperSlide key={comment._id}>
                  <div className="bg-white shadow-lg rounded-lg p-6 flex flex-col items-center">
                    <h3 className="text-lg font-semibold">
                      {comment.userName}
                    </h3>
                    <p className="text-gray-500 text-sm">
                      {new Date(comment.date).toLocaleDateString()}
                    </p>
                    <p className="text-gray-700 text-center mt-4">
                      {comment.comment}
                    </p>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      )}

      {/* Modal for Gallery */}
      {isModalOpen && (
        <div
          id="modal-background"
          onClick={closeModal}
          className="fixed top-0 left-0 right-0 bottom-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
        >
          <div className="relative w-full max-w-4xl h-[50vh] md:h-[70vh] bg-white rounded-lg shadow dark:bg-gray-700">
            <Swiper
              slidesPerView={1}
              spaceBetween={10}
              loop={true}
              pagination={{ clickable: true }}
              className="h-full"
            >
              {tour.gallery.map((imgUrl, index) => (
                <SwiperSlide key={index}>
                  <img
                    src={imgUrl}
                    className="w-full h-full object-cover"
                    alt={`Gallery image ${index + 1}`}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      )}

      <Footer />
    </>
  );
};

export default TourDetail;
