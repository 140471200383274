import React, { useState } from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";

const Profile = () => {
  const [activeTab, setActiveTab] = useState("personalInfo");
  const [personalInfo, setPersonalInfo] = useState({
    name: "Ahmet Yılmaz",
    email: "ahmet.yilmaz@example.com",
    phone: "555-123-4567",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setPersonalInfo({ ...personalInfo, [name]: value });
  };

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div className="profile-page">
      <Header />
      <div className="container mx-auto mt-24 mb-6 p-6 bg-white rounded-lg shadow-lg">
        <h1 className="text-2xl font-semibold mb-6">Profilim</h1>

        {/* Tabs */}
        <div className="flex flex-wrap justify-between mb-6 border-b">
          <button
            onClick={() => handleTabChange("personalInfo")}
            className={`py-2 px-4 ${
              activeTab === "personalInfo"
                ? "border-b-2 border-orange-500 text-orange-500"
                : "text-gray-600"
            }`}
          >
            Kişisel Bilgilerim
          </button>
          <button
            onClick={() => handleTabChange("addresses")}
            className={`py-2 px-4 ${
              activeTab === "addresses"
                ? "border-b-2 border-orange-500 text-orange-500"
                : "text-gray-600"
            }`}
          >
            Adreslerim
          </button>
          <button
            onClick={() => handleTabChange("reservations")}
            className={`py-2 px-4 ${
              activeTab === "reservations"
                ? "border-b-2 border-orange-500 text-orange-500"
                : "text-gray-600"
            }`}
          >
            Rezervasyonlarım
          </button>
          <button
            onClick={() => handleTabChange("contactInfo")}
            className={`py-2 px-4 ${
              activeTab === "contactInfo"
                ? "border-b-2 border-orange-500 text-orange-500"
                : "text-gray-600"
            }`}
          >
            İletişim Bilgilerim
          </button>
        </div>

        {/* Tab Content */}
        {activeTab === "personalInfo" && (
          <div>
            <h2 className="text-xl font-semibold mb-4">Kişisel Bilgilerim</h2>
            <div className="grid gap-4">
              <div>
                <label className="block text-gray-600">Ad Soyad</label>
                <input
                  type="text"
                  name="name"
                  value={personalInfo.name}
                  onChange={handleInputChange}
                  className="w-full p-2 border border-gray-300 rounded"
                />
              </div>
              <div>
                <label className="block text-gray-600">Email</label>
                <input
                  type="email"
                  name="email"
                  value={personalInfo.email}
                  onChange={handleInputChange}
                  className="w-full p-2 border border-gray-300 rounded"
                />
              </div>
              <div>
                <label className="block text-gray-600">Telefon</label>
                <input
                  type="text"
                  name="phone"
                  value={personalInfo.phone}
                  onChange={handleInputChange}
                  className="w-full p-2 border border-gray-300 rounded"
                />
              </div>
              <button className="mt-4 bg-orange-500 text-white py-2 px-4 rounded hover:bg-orange-600 transition-all">
                Bilgileri Güncelle
              </button>
            </div>
          </div>
        )}

        {activeTab === "addresses" && (
          <div>
            <h2 className="text-xl font-semibold mb-4">Adreslerim</h2>
            <p>Burada kullanıcı adresleri listelenecek.</p>
            {/* Adres ekleme ve düzenleme işlemleri burada yapılabilir */}
          </div>
        )}

        {activeTab === "reservations" && (
          <div>
            <h2 className="text-xl font-semibold mb-4">Rezervasyonlarım</h2>
            <p>Burada kullanıcının rezervasyonları listelenecek.</p>
            {/* Rezervasyon bilgileri burada gösterilecek */}
          </div>
        )}

        {activeTab === "contactInfo" && (
          <div>
            <h2 className="text-xl font-semibold mb-4">İletişim Bilgilerim</h2>
            <p>Burada kullanıcının iletişim bilgileri düzenlenecek.</p>
            {/* İletişim bilgilerini ekleme veya düzenleme işlemleri burada yapılabilir */}
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default Profile;
